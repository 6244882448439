import React from 'react'
import Text from '../../ui/Text'
// import LoginUsingFacebook from './LoginUsingFacebook'
import LoginUsingGoogle from './LoginUsingGoogle'
import LoginUsingEmail from './LoginUsingEmail'
import LoginUsingApple from './LoginUsingApple'
import Loading from '../../../modules/_components/Loading'
import ProcessFailedModal from '../../ui/modals/ProcessFailedModal'
import ProcessFailedModalCrossIcon from '../../../icons/ProcessFailedModalCross'
import styled from 'styled-components'

const SocialButtons = ({ _this }) => {
  return (
    <>
      <Loading
        visible={_this.state.isLoading}
        title={""}
        text={`Logging In...`}
        modal_opaque={false}
      />
      <ProcessFailedModal
        title={"Process Failed"}
        banner={<ProcessFailedModalCrossIcon />}
        description={_this.state.errorMessage}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={_this.state.isFailed}
        onOk={() => _this.SetState({ isFailed: false })}
        onCancel={() => _this.SetState({ isFailed: false })} // x and cancel button
      />
      <SocialButtonContainer>
        <Text style={{ color: "#727D8A" }} level={4}> Or Continue With </Text>
        <StyledSocialButtons>
          {/* <LoginUsingFacebook _this={_this}/> */}
          <LoginUsingGoogle _this={_this} />
          <LoginUsingEmail _this={_this} />
          <LoginUsingApple _this={_this} />
        </StyledSocialButtons>
      </SocialButtonContainer>
    </>
  )
}

export default SocialButtons

const SocialButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
  gap: 20px;
`

const StyledSocialButtons = styled('div')`
  display: flex;
  justify-content: center;
  gap: 20px;
`
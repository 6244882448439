import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Row, Col, Typography } from "antd";
import styled from "styled-components";
// import UI from "../../../shared/Components/UI/js";
import DropdownIcon from "../../../../icons/Dropdown";
import ActionViewIcon from "../../../../icons/ActionViewIcon";
import SearchIcon from "../../../../icons/Search";
import Container from "../../../../ComponentsFC/ui/Container";
import ContainerLabelPrimary from "../../../../ComponentsFC/ui/ContainerLabelPrimary";
import CustomDatePicker from "../../../../ComponentsFC/ui/forms/DatePicker";
import CustomFieldLabel from "../../../../ComponentsFC/ui/forms/FieldLabel";
import CustomInputField from "../../../../ComponentsFC/ui/forms/InputField";
import CustomSelectField from "../../../../ComponentsFC/ui/forms/SelectField";
import DataTable from "../../../../ComponentsFC/DataTable";
// import toTitleCase from "../../../../utils/toTitleCase";
import ServiceAdminDoctorAccountsApi from "../../../../api_services/Admin/doctor-accounts-api";
// import { adminDoctorAccountsApi as DUMMYadminDoctorAccountsApi } from "../../../../__fakeApi__/AdminDoctorAccountsApi";
// import FileDownload from "js-file-download";
import Loading from "../../../../modules/_components/Loading";

const onlyShowEntriesSelectOptions = [
  { type: "5 Entries", value: "5" },
  { type: "10 Entries", value: "10" },
  { type: "30 Entries", value: "30" },
];

const DeletedDoctorsList = () => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [tableEntries, setTableEntries] = useState(30);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState();

  const [dateDeleted, setDateDeleted] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [appliedSearchQuery, setAppliedSearchQuery] = useState("");

  const getDeletedDoctorsList = useCallback(async () => {
    try {
      let urlParams = {};
      // urlParams.sort = "deletedAt";

      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      if (dateDeleted) {
        urlParams.startDate = dateDeleted;
        urlParams.endDate = dateDeleted;
      }

      if (appliedSearchQuery) {
        urlParams.searchString = appliedSearchQuery;
      }

      setIsScreenLoading(true);
      setIsTableLoading(true);
      const apiResponse =
        await ServiceAdminDoctorAccountsApi.getDeletedDoctorsList(urlParams);
      // const apiResponse =
      //   await DUMMYadminDoctorAccountsApi.getDeletedDoctorsDetails();
      setIsScreenLoading(false);
      setIsTableLoading(false);
      // console.log("GET DELETED DOCTOR LIST: ", apiResponse);
      setTotalRecords(apiResponse?.results?.total); // depending on return API JSON Structure
      setTableData(apiResponse?.data?.map((obj) => ({ ...obj, key: obj._id })));
      // //Dummy API
      // setTotalRecords(apiResponse?.d.count);
      // setTableData(
      //   apiResponse?.d.data?.map((obj) => ({ ...obj, key: obj._id }))
      // );
    } catch (err) {
      console.error(err);
    } finally {
      setIsScreenLoading(false);
      setIsTableLoading(false);
    }
  }, [currentPageNum, tableEntries, dateDeleted, appliedSearchQuery]);

  useEffect(() => {
    getDeletedDoctorsList();
  }, [getDeletedDoctorsList]);

  const handleChangeDateDeleted = (_, dateString) => {
    setDateDeleted(dateString);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    //Trigger getDeletedDoctorsList if searchQuery inputField is empty
    if (!searchQuery) {
      setAppliedSearchQuery("");
    }
  }, [searchQuery]);

  const handleAppliedSearchQuery = () => {
    setAppliedSearchQuery(searchQuery);
  };

  const handlePaginateTable = (antdSelectedPageNum) => {
    setCurrentPageNum(antdSelectedPageNum);
  };

  const tableColumnHeader = [
    {
      title: "Doctors Name",
      dataIndex: "name",
      key: "name",
      render: (name) => {
        if (name) {
          return name;
          // return `${name[0]?.given?.join(" ")} ${name[0]?.family}`;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      render: (email) => {
        if (email) {
          // return telecom[1].value;
          return email;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Contact Number",
      dataIndex: ["doctor", "telecom"],
      key: ["doctor", "telecom"],
      render: (telecom) => {
        if (telecom) {
          return telecom[0]?.value?.length > 0 ? `0${telecom[0].value}` : "--";
        } else {
          return "--";
        }
      },
    },
    {
      title: "Date Deleted",
      dataIndex: "deletedAt",
      key: "deletedAt",
      render: (date) => {
        if (date) {
          return moment(date).utc().format("MM/DD/YYYY");
        } else {
          return "--";
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (action, rowData) => {
        return (
          <ActionViewIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(
                "/admin/doctor-accounts/deleted-doctors/view-details",
                { data: rowData }
              )
            }
          />
        );
        // return (
        //   <ActionViewIcon
        //     style={{ cursor: "pointer" }}
        //     onClick={() => console.log("TRIGGERED ACTION", rowData)}
        //   />
        // );
      },
    },
  ];

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={""}
        modal_opaque={false}
      />
      <Container>
        <ContainerLabelPrimary>Deleted Doctors</ContainerLabelPrimary>
        {/* ANTD ROW 24 GRID LAYOUT 8x8x8 (span) = 24 */}
        <Row>
          <Col span={11}>
            <CustomFieldLabel>Date Deleted</CustomFieldLabel>
            <Row>
              <CustomDatePicker
                placeholder="Select Date"
                style={{
                  width: 150,
                }}
                onChange={handleChangeDateDeleted}
              />
            </Row>
          </Col>
        </Row>
      </Container>

      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <Col span={3}>
            <CustomSelectField
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="30"
              style={{
                width: 190,
              }}
              selectoptions={onlyShowEntriesSelectOptions}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            />
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            <CustomInputField
              placeholder="Enter Keyword"
              allowClear
              suffix={<SearchIcon />}
              value={searchQuery}
              onChange={(e) => {
                handleChangeSearchQuery(e);
              }}
              onPressEnter={handleAppliedSearchQuery}
              style={{ width: 285, marginLeft: "auto" }} //remove auto if you'll activate Export to CSV
            />
            {/* <StyledButton type="primary" onClick={generateDeletedDoctorsListCSV}>
              Export to CSV
            </StyledButton> */}
          </Col>
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isTableLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default DeletedDoctorsList;

// CSS Styled Components ----------------------

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px !important;
  }

  /* // 2ND COLUMN OF TABLE
  .ant-table-row .ant-table-cell:nth-child(2) {
    white-space: pre;
  } */
`;

const Toolbar = styled(Row)`
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 20px; // 20px
  line-height: 20px; // 20px
  letter-spacing: 0;
`;

// const StyledButton = styled(Button)`
//   height: 60px;
//   border-radius: 12px;
//   border: none;
//   background-color: #0e4d94;
//   font-family: Poppins;
//   font-weight: 500;
//   font-size: 20px; // 20px
//   margin-left: 20px;
//   padding-left: 20px;
//   padding-right: 20px;
//   &:hover {
//     background-color: #43c8ff;
//   }
// `;

// const CellStyle = styled(Typography.Text)`
//   // Dynamically render style using props of this component
//   color: ${(props) =>
//     props.status === "Approved"
//       ? "#64C31E"
//       : props.status === "Pending"
//       ? "#00CBFF"
//       : props.status === "Disapproved"
//       ? "#CC0404"
//       : "#000000"};
// `;

// const StyledTooltip = styled(Tooltip)`
//   /* div.ant-tooltip-inner {
//     white-space: normal;
//   } */
// `;

import React, { useEffect, useState } from "react";
import UI from "../../shared/Components/UI/js";
import {
  ScrollView,
  Text,
  View,
  StyleSheet,
} from "../../shared/custom-react-native";
import PatientDetails from "./PatientDetails";
import ConsultationNotes from "./encounterNotes/consultation-notes/ConsultationNotes";
import MedicalCertificate from "./encounterNotes/medical-certificate/MedicalCertificate";
import MedicalRecords from "./encounterNotes/MedicalRecords";
import OrderPrescription from "./encounterNotes/order-prescription/OrderPrescription";
import ViewFiles from "./encounterNotes/view-files/ViewFiles";
import { Failed, Success, RadioButtonModal, SystemNoticeCustomFailedModal, CustomConfirmation } from "../_components/Modal";
import jwt from "jsonwebtoken";
import ReferPatient from "./encounterNotes/refer-patient/ReferPatient";
import LaboratoryOrder from "./encounterNotes/laboratory-order/LaboratoryOrder";
import { Tabs } from "antd";
import styled from "styled-components";

const side_padding = 0;

const PatientHistory = (props) => {
  const { appointment, width } = props._this.state;
  const notes = appointment && appointment.notes;
  const index = props._this;
  const autoSaveFailed = props._this.state.autoSaveFailed;
  const lastSavedDate = props._this.state.lastSavedDate;

  const [autoSaving, setAutoSaving] = useState(props._this.state.autoSaving);
  const [activeKey, setActiveKey] = useState(0)

  const [isIssueOrderToPatient, setIsIssueOrderToPatient] = useState(false)
  const [isIssuePrescriptionToPatient, setIsIssuePrescriptionToPatient] = useState(false)
  const [isIssueMedicalCertificateToPatient, setIsIssueMedicalCertificateToPatient] = useState(false)
  // const [isIssueLaboratoryOrderToPatient, setIsIssueLaboratoryOrderToPatient] = useState(false)

  const [laboratorySelected, setLaboratorySelected] = useState([]);

  const [encounterNotesData, setEncounterNotesData] = useState({
    consultations: [],
    orders: [],
    prescriptions: [],
    medicalcertificates: [],
  });

  const [consultationNotes, setconsultationNotes] = useState({
    notes: "",
    assessment: "",
    patientInstruction: "",
    followupSchedule: "",
    icd: {
      code: "",
      description: "",
    },
    covidAssessment: {
      isCovidRelated: "",
      isIquireCovidConsultation: "",
      paitentsCovidAssessment: "",
    },
    dohCovidReferral: {
      isPatientForErReferral: "",
      isPatientForSpecialistReferral: "",
    },
  });

  const [orderPrescriptionFormInput, setOrderPrescriptionFormInput] = useState({
    orders: [
      {
        diagnosticsItem: "",
      },
    ],
    prescriptions: [
      {
        medication: "",
        number: "",
        // medicalTest:'',
        notes: "",
        brandname: "",
      },
    ],
  });

  const [medicalCertifcateFormInput, setMedicalCertifcateFormInput] = useState({
    consultationFindings: "",
    recommendation: "",
    fitToWorkStatus: "",
    fitToWorkOthers: "",
  });

  const [electronicMedicalRecords, setElectronicMedicalRecords] =
    useState(null);

  useEffect(() => {
    if (notes) {
      setEncounterNotesData({
        ...encounterNotesData,
        orders: notes?.orders || [],
        prescriptions: notes?.prescriptions || [],
        consultations: notes?.consultations || [],
        medicalcertificates: notes?.medicalCertificates,
      });

      if (notes?.consultations[0]?.followupSchedule) {
        UI.set_date_value(
          "start_date",
          new Date(notes?.consultations[0]?.followupSchedule)
        );
      }

      setconsultationNotes({
        notes: notes?.consultations[0]?.notes || "",
        assessment: notes?.consultations[0]?.assessment || "",
        patientInstruction: notes?.consultations[0]?.patientInstruction || "",
        followupSchedule: notes?.consultations[0]?.followUpSchedule || "",
        icd: notes?.consultations[0]?.icd || [],
        covidAssessment: {
          isCovidRelated:
            notes?.consultations[0]?.covidAssessment?.isCovidRelated ?? null,
          isIquireCovidConsultation:
            notes?.consultations[0]?.covidAssessment
              ?.isIquireCovidConsultation ?? null,
          paitentsCovidAssessment:
            notes?.consultations[0]?.covidAssessment?.paitentsCovidAssessment ||
            null,
        },
        dohCovidReferral: {
          isPatientForErReferral:
            notes?.consultations[0]?.dohCovidReferral?.isPatientForErReferral ??
            null,
          isPatientForSpecialistReferral:
            notes?.consultations[0]?.dohCovidReferral
              ?.isPatientForSpecialistReferral ?? null,
        },
      });
      setOrderPrescriptionFormInput({
        orders:
          notes?.orders.length > 0
            ? notes?.orders
            : [
                {
                  diagnosticsItem: "",
                },
              ],
        prescriptions:
          notes?.prescriptions.length > 0
            ? notes?.prescriptions
            : [
                {
                  medication: "",
                  number: "",
                  // medicalTest:'',
                  notes: "",
                  brandname: "",
                },
              ],
      });
      setMedicalCertifcateFormInput({
        consultationFindings:
          notes?.medicalCertificates.length > 0
            ? notes?.medicalCertificates[0]?.consultationFindings
            : "",
        recommendation:
          notes?.medicalCertificates.length > 0
            ? notes?.medicalCertificates[0]?.recommendation
            : "",
        fitToWorkStatus:
          notes?.medicalCertificates.length > 0
            ? notes?.medicalCertificates[0]?.fitToWorkStatus
            : "",
        fitToWorkOthers:
          notes?.medicalCertificates.length > 0
            ? notes?.medicalCertificates[0]?.fitToWorkOthers
            : "",
      });
      setLaboratorySelected(props._this.state.appointment?.notes?.laboratory?.orders || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  const onAddConsultationNotes = (item) => {
    const payload = { ...encounterNotesData, consultations: [item] };
    setEncounterNotesData(payload);
    return payload;
    // saveEntry(payload);
  };

  const onAddOrderPrescriptions = (item) => {
    const payload = {
      ...encounterNotesData,
      orders: [...item.orders],
      prescriptions: [...item.prescriptions],
    };

    setEncounterNotesData(payload);
    return payload;
    // saveEntry(payload);
  };

  const onAddMedicalCertifcateFormInput = (item) => {
    const payload = { ...encounterNotesData, medicalcertificates: [item] };
    setEncounterNotesData(payload);
    return payload;
    // saveEntry(payload);
  };

  const submitFinalize = (signatureImage, optionSelected, endNotes) => {
    props._this.show_loading();
    const finalNotes = endNotes?.charAt(0) === " " ? endNotes : " " + endNotes;

    const payload = {
      ...encounterNotesData,
      signature: signatureImage,
      endReason: optionSelected,
      endNotes: finalNotes,
    };

    const encode = jwt
      .sign(payload, "secret")
      .replace(/\//g, "slash")
      .replace(/\+/g, "plus");

    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/appointments/clinical-notes/" + appointment.id,
      params: {
        encode,
      },
      onFail: (err) => {
        const data = err.data;
        let error_message = data.message;
        if (!error_message) {
          error_message = data.m;
        }
        props._this.hide_loading();
        props._this.show_modal(
          <Failed
            title={"Failed"}
            description={`${error_message} please refresh the browser and try again`}
            onDismiss={() => {
              props._this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async () => {
        props._this.hide_loading();
        props._this.show_modal(
          <Success
            title={"Success!"}
            descriptionCenter={"Successfully Sign and Finalized."}
            onCancel={() => {
              props._this.hide_modal();
            }}
            onDismiss={() => {
              props._this.stop_call().then(() => {
                window.location.href = "/onboard/dashboard";
              });
              props._this.hide_modal();
            }}
          />
        );
      },
    });
  };

  const saveEntry = (id, payload) => {
    // console.log(appointment)
    // console.log(props._this.state.appointment._id)
    // return
    setAutoSaving(true);
    index.saveFieldValueDebounced(
      id,
      payload,
      props._this.state.appointment._id,
      onSuccessCallBack,
      onFailCallBack
    );
  };

  const onSuccessCallBack = (payload) => {
    // method called by onboard-encounter/index.js
    setAutoSaving(false);
  };

  const onFailCallBack = (payload) => {
    // method called by onboard-encounter/index.js
    setAutoSaving(false);
  };

  const topBar = appointment?.practitioner.professionalType === false 
    ? 
      [{
        key: "consultation_notes",
        label: "Consultation Notes",
        children: <ConsultationNotes
          onAddConsultationNotes={onAddConsultationNotes}
          consultationNotes={consultationNotes}
          setconsultationNotes={setconsultationNotes}
          _this={props._this}
          notes={notes}
          saveEntry={saveEntry}
        />
      }]
    :
      [
        {
          id: "consultation_notes",
          label: "Consultation Notes",
          children: <ConsultationNotes
            onAddConsultationNotes={onAddConsultationNotes}
            consultationNotes={consultationNotes}
            setconsultationNotes={setconsultationNotes}
            _this={props._this}
            notes={notes}
            saveEntry={saveEntry}
          />
        },
        {
          id: "order_prescription",
          label: "Order/Prescription",
          children: <OrderPrescription
            isIssueOrderToPatient={isIssueOrderToPatient}
            setIsIssueOrderToPatient={setIsIssueOrderToPatient}
            isIssuePrescriptionToPatient={isIssuePrescriptionToPatient}
            setIsIssuePrescriptionToPatient={setIsIssuePrescriptionToPatient}
            _this={props._this}
            onAddOrderPrescriptions={onAddOrderPrescriptions}
            setOrderPrescriptionFormInput={setOrderPrescriptionFormInput}
            orderPrescriptionFormInput={orderPrescriptionFormInput}
            saveEntry={saveEntry}
            encounterNotesData={encounterNotesData}
          />
        },
        {
          id: "medical_certificate",
          label: "Medical Certificate",
          children:  <MedicalCertificate
            isIssueMedicalCertificateToPatient={isIssueMedicalCertificateToPatient}
            setIsIssueMedicalCertificateToPatient={setIsIssueMedicalCertificateToPatient}
            onAddMedicalCertifcateFormInput={onAddMedicalCertifcateFormInput}
            medicalCertifcateFormInput={medicalCertifcateFormInput}
            setMedicalCertifcateFormInput={setMedicalCertifcateFormInput}
            _this={props._this}
            saveEntry={saveEntry}
            encounterNotesData={encounterNotesData}
          />
        },
        {
          key: "medical_records",
          label: "Medical Records",
          children: (
            <MedicalRecords
              electronicMedicalRecords={electronicMedicalRecords}
              setElectronicMedicalRecords={(data) =>
                setElectronicMedicalRecords(data)
              }
              patient={props?._this?.state?.appointment?.patient}
              showModal={(elem) => {
                props._this.show_modal(elem);
              }}
              hideModal={(elem) => {
                props._this.hide_modal(elem);
              }}
            />
          )
        },
        {
          key: "laboratory_order",
          label: "Laboratory Order",
          children: <LaboratoryOrder 
          laboratorySelected={laboratorySelected}
          setLaboratorySelected={setLaboratorySelected}
          // isIssueLaboratoryOrderToPatient={isIssueLaboratoryOrderToPatient}
          // setIsIssueLaboratoryOrderToPatient={setIsIssueLaboratoryOrderToPatient}
          _this={props._this} />
        },
        {
          key: "view_files",
          label: "View Files",
          children: <ViewFiles _this={props._this} />
        },
      ];
  
  const issueEdocsToPatient = () => {
    props._this.hide_modal();
    props._this.show_loading();
  
    const payload = {
      ...encounterNotesData,
      // signature: "This document was electronically signed",
      isIssueMedicalCertificate: isIssueMedicalCertificateToPatient,
      isIssueOrder: isIssueOrderToPatient,
      isIssuePrescription: isIssuePrescriptionToPatient,
      isSendEdocs: true,
    };

    const encode = jwt
      .sign(payload, "secret")
      .replace(/\//g, "slash")
      .replace(/\+/g, "plus");

    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/appointments/clinical-notes/" + appointment.id,
      params: {
        encode,
      },
      onFail: (err) => {
        const data = err.data;
        let error_message = data.message;
        if (!error_message) {
          error_message = data.m;
        }
        props._this.hide_loading();
        props._this.show_modal(
          <Failed
            title={"Failed"}
            description={`${error_message} please refresh the browser and try again`}
            onDismiss={() => {
              props._this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async () => {
        props._this.hide_loading();
        props._this.show_modal(
          <Success
            title={"Success!"}
            description={"You have successfully sent the edocuments to your patient."}
            onDismiss={() => {
              showAndFinalizeModal();
            }}
          />
        );
      },
    });
  }
  
  let textDisplayLabelEdocs = [];
  let textRemoveLabelEdocs = [];

  const showModalWithValidation = () => {
    //  VALIDATE ISSUE TO PATIENT TICK BOX BEFORE SIGN AND FINALIZE

    const isOrderNotEmpty = orderPrescriptionFormInput.orders[0].diagnosticsItem
    const isPrescriptionNotEmpty = !Object.keys(orderPrescriptionFormInput.prescriptions[0]).every(key => key === "_id" ? true : orderPrescriptionFormInput.prescriptions[0][key] === '')
    
    if (isOrderNotEmpty) {
      textDisplayLabelEdocs.push("Doctor's Order")
    } 

    if (isPrescriptionNotEmpty) {
      textDisplayLabelEdocs.push("Doctor's Prescription")
    } 

    const isMedicalCertificateNotEmpty  = !Object.values(medicalCertifcateFormInput).every(value => value === "")
    if (isMedicalCertificateNotEmpty) {
      textDisplayLabelEdocs.push("Medical Certificate")
    } 

    // if (laboratorySelected.length > 0) {
    //   textDisplayLabelEdocs.push("Laboratory Order")
    // }

    if (textDisplayLabelEdocs.length > 0) {
      for (const row of textDisplayLabelEdocs) {
        if ((!isIssueOrderToPatient) && (!isIssuePrescriptionToPatient) && (!isIssueMedicalCertificateToPatient)) {
          return showSystemModalTickboxEdocs()
        }

        if (row === "Doctor's Order" && !isIssueOrderToPatient) {
          textRemoveLabelEdocs.push(row)
          const removedFilteredTextDisplayLabelEdocs = textDisplayLabelEdocs.filter(a => a !== row); 
          textDisplayLabelEdocs = removedFilteredTextDisplayLabelEdocs
          return showSystemModalTickboxEdocs()
        } else if (row === "Doctor's Prescription" && !isIssuePrescriptionToPatient) {
          textRemoveLabelEdocs.push(row)
          const removedFilteredTextDisplayLabelEdocs = textDisplayLabelEdocs.filter(a => a !== row); 
          textDisplayLabelEdocs = removedFilteredTextDisplayLabelEdocs
          return showSystemModalTickboxEdocs()
        } else if (row === "Medical Certificate" && !isIssueMedicalCertificateToPatient) {
          textRemoveLabelEdocs.push(row)
          const removedFilteredTextDisplayLabelEdocs = textDisplayLabelEdocs.filter(a => a !== row); 
          textDisplayLabelEdocs = removedFilteredTextDisplayLabelEdocs
          return showSystemModalTickboxEdocs()
        } else {
          showConfirmationTickboxEdocs()
        }

        // else if (row === "Laboratory Order" && !isIssueLaboratoryOrderToPatient) {

        //   textRemoveLabelEdocs.push(row)
        //   const removedFilteredTextDisplayLabelEdocs = textDisplayLabelEdocs.filter(a => a !== row); 
        //   textDisplayLabelEdocs = removedFilteredTextDisplayLabelEdocs
        //   return showSystemModalTickboxEdocs()
        // } 
          
      }
    }  else {
      return props._this.show_modal(
        <CustomConfirmation
          title={"Confirmation"}
          imageBanner={UI.CONFIRMATION_BANNER}
          text={"No e-document/s were being set to send to your patient."}
          backgroundColor={"#3498db"}
          onOk={{
            text: "Proceed",
            method: () => {
              showAndFinalizeModal();
            },
          }}
          onCancel={{
            text: "Back",
            method: () => {
              props._this.hide_modal();
            },
          }}
        />
      );
    }

  }

  const showAndFinalizeModal = () => { 
    props._this.show_modal(
      <RadioButtonModal
        title="SIGN AND FINALIZE"
        body="What is the reason for ending the consultation?"
        name="signAndFinalize"
        titleStyles={{ ...styles.modalTitle }}
        bodyStyles={{ ...styles.modalBody }}
        optionButtonStyles={{ ...styles.radioButtons }}
        optionLabelStyles={{ ...styles.modalOptions }}
        submitButtonStyles={{ margin: "auto" }}
        cancelButtonStyles={{}}
        logoSource={UI.COMING_SOON}
        width={545}
        hideCancelButton={true}
        customContent={
          <textarea
            style={{
              fontSize: "14px",
              resize: "none",
              color: "black",
              height: "105px",
              width: "100%",
              padding: "10px 14.44px",
              borderRadius: "10px",
              border: "2px solid #C9C9C9",
              marginTop: "5px",
            }}
            placeholder={"Tell us more about the situation"}
            name="signAndFinalizeNotes"
            onChange={(e) => {
              props._this.setState({ endNotes: e.target.value });
            }}
            defaultValue={
              appointment?.endNotes
                ? props._this.state.endNotes ?? ""
                : ""
            }
          />
        }
        options={[
          {
            label:
              "Unexpected End of Call due to Technical Issue for Doctor",
            value:
              "Unexpected End of Call due to Technical Issue for Doctor",
          },
          {
            label:
              "Unexpected End of Call due to Technical Issue for Patient",
            value:
              "Unexpected End of Call due to Technical Issue for Patient",
          },
          { label: "Successful Call", value: "Successful Call" },
        ]}
        defaultOptionChoice={appointment.endReason}
        onOk={{
          label: "Submit",
          method: (val) => {
            if (val) {
              props._this.hide_modal();
              if (
                encounterNotesData.consultations.length === 0 ||
                !encounterNotesData.consultations[0].notes
              ) {
                props._this.show_modal(
                  <Failed
                    title={"Failed"}
                    description={"You are required to provide your consultation notes"}
                    onDismiss={() => {
                      props._this.hide_modal();
                    }}
                  />
                );
              } else {
                submitFinalize(
                  "This document was electronically signed",
                  val,
                  props._this.state.endNotes
                );
              }
            }
          },
        }}
        onCancel={{
          method: () => {
            props._this.hide_modal();
          },
        }}
      />
    );
  }

  const showConfirmationTickboxEdocs = () => {
    props._this.show_modal(
      <CustomConfirmation
        title={"Confirmation"}
        imageBanner={UI.CONFIRMATION_BANNER}
        text={"You have set to send the following e-documents to your patient. Kindly review before signing this consultation."}
        description={
          <div className="flex flex-col gap-2">
            {
              // .filter(a => a !== [0])
              textDisplayLabelEdocs.map(res => (
                <div className="flex gap-2">
                  <img src={UI.CHECK_ICON} alt="CHECK_ICON" /> <span>{res}</span>
                </div>
              ))
            }
          </div>
        }
        backgroundColor={"#3498db"}
        onOk={{
          text: "Proceed",
          method: () => {
            issueEdocsToPatient()
          },
        }}
        onCancel={{
          text: "Back",
          method: () => {
            props._this.hide_modal();
          },
        }}
      />
    );
  }

  const showSystemModalTickboxEdocs = () => {
    
    if (textDisplayLabelEdocs.length > 0) {
      return props._this.show_modal(
        <SystemNoticeCustomFailedModal 
          title="System Notice"
          buttonTextOk="Yes, I do not want to issue the edocument"
          buttonTextCancel="No, send it to patient"
          imageBanner={UI.SYSTEM_NOTICE_BANNER}
          description_title={`
            Our system noticed that you have placed information on ${(textRemoveLabelEdocs.length > 0 ? textRemoveLabelEdocs : textDisplayLabelEdocs).join(", ")}. 
          `}
          description={"Are you sure that you don’t want to send it to your patient?"}
          _this={props._this}
          onOk={() => {
            if (textRemoveLabelEdocs.length > 0) { 
              showConfirmationTickboxEdocs();
            } else {
              showAndFinalizeModal();
            }
            
            
          }}
          onDismiss={() => {
            props._this.hide_modal();
          }}
        />
      );
    }
  }

  return (
    <ScrollView style={{ padding: side_padding, width: "100%" }}>
      <View className="p-3">
        <PatientDetails _this={props._this} />

        {props.width < 800 && (
          <div className="w-52">
            {UI.box(20)}
            <div
              onClick={props.onReturnToCall}
              className={`cursor-pointer flex gap-1 focus:bg-red-200 py-3 px-4 justify-center items-center rounded-lg font-semibold`}
              style={{ background: "#0E4D94", userSelect: "none" }}
            >
              <div className="flex items-center justify-center gap-2">
                <strong className="text-white" style={{ fontSize: "16px" }}>
                  Return to Call
                </strong>
              </div>
            </div>
          </div>
        )}

        {UI.box(20)}

        <View>
          <StyledTabs
            defaultActiveKey={activeKey}
            onChange={(e) => {
              let toNumber = parseInt(e)
              setActiveKey(toNumber)
            }}
          >
            {
              topBar.map((item, i) => {
                return (
                <StyledTabsTabPane
                  tab={
                    <div className="flex gap-2 items-center">
                        <img
                          src={activeKey === parseInt(i) ? UI.FILE_1 : UI.FILE_2} alt="UI.FILE_1"
                          height={15}
                          width={15}
                        />
                      <span className="">
                        {item.label}
                      </span>
                    </div>
                  }
                  key={i}
                >
                  {item.children}
                </StyledTabsTabPane>)
              })
            }
          </StyledTabs>
        </View>

        <View style={styles.lastSaved} className="rounded-b-lg p-3">
          {autoSaving ? (
            <span style={styles.loader}></span>
          ) : (
            <span>
              <img
                style={{ width: 20, marginRight: 5 }}
                src={!autoSaveFailed ? UI.SAVED_ICON : UI.X_ICON}
                alt=""
              />
            </span>
          )}
          {!autoSaveFailed ? (
            <span
              style={{
                color: UI.colors.header,
                fontSize: "14px",
                textAlign: "right",
              }}
            >
              {lastSavedDate ? "Last Saved: " + lastSavedDate : ""}
            </span>
          ) : (
            <span
              style={{ color: "#F00", fontSize: "14px", textAlign: "right" }}
            >
              Something went wrong. Please try again.
            </span>
          )}
        </View>

        {UI.box(10)}

        <View
          style={{
            justifyContent: "",
            padding: "0px 12px",
            flexDirection: "row",
          }}
        >
          <div className="flex gap-3">
            <button
              onClick={() => {
                props._this.show_modal(
                  <ReferPatient _this={props._this} />
                );
              }}
              className="p-3 font-semibold flex rounded-md text-white"
              style={{ background: UI.colors.secondary }}
            >
              Refer Patient
            </button>
          </div>
        </View>

        {UI.box(20)}

        <View
          style={{
            justifyContent: "",
            padding: "0px 12px",
            flexDirection: "row",
          }}
        >
       
        </View>

        {UI.box(20)}

        <View
          style={{
            justifyContent: "",
            padding: "0px 12px",
            flexDirection: "row",
          }}
        >
          <div>
            <button
              onClick={() => {
                props._this.end_call(appointment._id, "return");
              }}
              className="p-3 font-semibold flex rounded-md text-white"
              style={{ background: UI.colors.primary }}
            >
              Return to Dashboard
            </button>
          </div>

          {UI.box(10)}

          {props.appointment && (
            <div
              className="flex items-center gap-2"
              style={{
                flexDirection: width > 800 ? "row" : "column",
                alignItems: width < 800 && "flex-start",
              }}
            >
              <button
                onClick={() => {
                  showModalWithValidation();
                }}
                className="p-3 font-semibold flex justify-end rounded-md text-white"
                disabled={autoSaving}
                style={{ background: autoSaving ? "#666666" : "#0E4D94" }}
              >
                Sign & Finalize
              </button>
            </div>
          )}
        </View>

        {UI.box(10)}

        <View
          style={{
            marginLeft: 15,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#767676",
              fontSize: "14px",
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            When you click the finish consultation button, the call will end for
            you and the patient.
          </Text>
          <Text
            style={{
              color: UI.colors.secondary,
              fontSize: "14px",
              justifyContent: "flex-end",
              textAlign: "right",
              fontWeight: "bold",
            }}
          >
            Last Sign Date:{" "}
            {appointment?.encounterCompleted
              ? UI.getCurrentTimeStampPHByMonthNameDayYear24H(
                  appointment.encounterCompleted
                )
              : ""}
          </Text>
        </View>

        {UI.box(10)}
      </View>
    </ScrollView>
  );
};

export default PatientHistory;

const styles = StyleSheet.create({
  modalTitle: {
    backgroundColor: UI.colors.header,
  },
  modalBody: {
    color: UI.colors.header,
    fontSize: "1.2em",
  },
  modalOptions: {
    color: UI.colors.black,
    fontSize: "1.2em",
  },
  radioButtons: {
    width: 20,
    height: 20,
    border: 0,
  },
  loader: {
    borderRadius: "50%",
    border: "3px solid #3498db",
    borderStyle: "dashed",
    width: 20,
    height: 20,
    WebkitAnimation: "spin 2s linear infinite" /* Safari */,
    animation: "spin 2s linear infinite",
    display: "inline-flex",
    marginRight: 10,
  },
  lastSaved: {
    width: "100%",
    background: "#F5FAFF",
    marginTop: -10,
    display: "inline-flex",
    justifyContent: "flex-end",
    flexFlow: "row nowrap",
  },
});

const StyledTabs = styled(Tabs)`
  /* width: 1143px; */

  .ant-tabs-tab {
    font-family: Poppins !important;
    font-weight: 400;
    padding: 10px 30px 10px 15px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: #D3DCE6 !important; 
  }

  .ant-tabs-tab-btn {
    /* padding: 6px 20px 6px 0px !important; */
    font-size:16px;
    color: #FFFFFF !important;
  }

  .ant-tabs-tab-active {
    background-color: #0E4D94 !important; 
  }

  .ant-tabs-tab-active img {
    color: red ;
  }
  .ant-tabs-nav-operations {
    padding-left: 8px;
  }

  .ant-tabs-nav-list {
    border: none !important;
  }

  .ant-tabs-tab:not(:nth-child(3)) {
    border-right: none !important;
  }

  .ant-tabs-nav-operations .ant-tabs-nav-more {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: #D3DCE6 !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px !important;
  }
`;

const StyledTabsTabPane = styled(Tabs.TabPane)`
  .ant-tabs-tabpane {
    margin-right: 10px !important;
  }
`;
